@if (!(utilities.appViewActive|async )) {
<mat-toolbar class="navBar" color="WHITE" (clickOutside)="close()">
  <div fxLayout="row" style="width: 100vw; justify-content: space-between">
    <div id="firstDiv">
      <div style="display: flex">
        <span [routerLink]="['']" style="display: flex; align-items: center"
          ><img
            src="../assets/logo-alone.webp"
            class="phonixLogo"
            alt="phonix logo"
          />
          @if (!marketing) {
          <span class="logoText">
            <span style="color: #ff8c00"> Phonix </span
            ><span style="color: #0091ff"> Health </span>
          </span>
          }</span
        >
      </div>
    </div>
    <div>
      @if ((userservice.isLogged|async)) {
      <div class="desktop-navbar">
        <div style="display: flex; align-items: center; gap: 3px">
          <!-- The following menu items will be hidden on both SM and XS screen sizes -->
          <!-- <a
                  *ngIf="!(userservice.isLogged|async)"
                  id="forum"
                  [routerLink]="['', 'nous']"
                  >
                  {{ "app.navbar.buttons.us" | translate }}
                </a>
                <a
                  *ngIf="!(userservice.isLogged|async)"
                  id="forum"
                  [routerLink]=" ['', 'contact']"
                  >
                  {{ "app.navbar.buttons.contact" | translate }}
                </a> -->
          <a
            [routerLink]="['', 'contact']"
            id="forum"
            style="margin-right: 0.5%"
            >{{ "app.navbar.buttons.contact" | translate }}</a
          >
          <a
            [routerLink]="['', 'forums']"
            id="forum"
            style="margin-right: 0.5%"
            >{{ "app.navbar.buttons.forum" | translate }}</a
          >
          <!-- <a
                  id="forum"
                  *ngIf="!(userservice.isLogged|async)"
                  [routerLink]=" ['', 'articles']"
                  >{{ "app.navbar.buttons.article" | translate }}</a
                  > -->
          <a
            id="forum"
            (click)="changeLang()"
            style="display: flex; gap: 5px; margin-right: 5px"
            >{{ "app.langObject.lang" | translate }} :
            <app-flags-icon [lang]="lang"></app-flags-icon>
          </a>
          <button
            [routerLink]="['', 'kids']"
            id="allkids"
            mat-raised-button
            color="primary"
          >
            <mat-icon data-nosnippet>groups</mat-icon>
            {{ "app.navbar.buttons.kids" | translate }}
          </button>
          <button
            id="allkids"
            [routerLink]="['', 'account']"
            mat-raised-button
            color="primary"
          >
            <mat-icon data-nosnippet>account_circle</mat-icon>
            {{ "app.navbar.buttons.account" | translate }}
          </button>
          @if ((userservice.user_info|async).isMember) {
          <button [routerLink]="['', 'tickets']" mat-mini-fab color="primary">
            <mat-icon data-nosnippet>bug_report</mat-icon>
          </button>
          } @if ((userservice.user_info|async).isAdmin) {
          <button [routerLink]="['', 'admin']" mat-mini-fab color="primary">
            <mat-icon>admin_panel_settings</mat-icon>
          </button>
          }
          <button
            id="allkids"
            [routerLink]="['', 'downloads']"
            color="accent"
            mat-raised-button
          >
            <mat-icon data-nosnippet>get_app</mat-icon>
            {{ "app.navbar.buttons.download" | translate }}
          </button>
          <button
            (click)="logout()"
            id="link"
            [routerLink]="['']"
            routerLinkActive="router-link-active"
            mat-raised-button
            color="warn"
          >
            <mat-icon data-nosnippet>logout</mat-icon>
            {{ "app.navbar.buttons.logout" | translate }}
          </button>
        </div>
      </div>
      <div class="mobile-navbar">
        @if (landingA) {
        <a (click)="openDiag()" style="margin-right: 20%"
          ><mat-icon data-nosnippet>phone_callback</mat-icon></a
        >
        }
        <div>
          <button mat-icon-button (click)="toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>
      } @if (!(userservice.isLogged|async)) {
      <div class="public-navbar">
        <div style="display: flex; align-items: center; gap: 5px">
          <div class="buttonDiv talkButtons">
            <button
              [routerLink]="['', 'login']"
              class="emailButton"
              mat-raised-button
              color="primary"
            >
              {{ "app.navbar.buttons.login" | translate }}
            </button>
          </div>
          <div class="buttonDiv talkButtons" (click)="openContactModal()">
            <button
              target="_blank"
              color="accent"
              class="emailButton"
              mat-raised-button
            >
              {{ "app.navbar.buttons.contact" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="public-mobile-navbar">
        @if (landingA) {
        <a (click)="openDiag()" style="margin-right: 20%"
          ><mat-icon data-nosnippet>phone_callback</mat-icon></a
        >
        }
        <button mat-icon-button (click)="toggle()" aria-label="Menu">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      }
    </div>
  </div>
</mat-toolbar>
}

<!-- Sidebar -->
<aside [@slideInOut]="menuState" class="sidebar">
  <mat-nav-list>
    @if ((userservice.isLogged|async)) { @if
    ((userservice.user_info|async).isAdmin ) {
    <a
      mat-list-item
      [routerLink]="['', 'admin']"
      routerLinkActive="active-link"
      class="list-item"
      (click)="toggle()"
      ><mat-icon
        style="color: var(--phonix-blue-white)"
        class="mobileIcons"
        data-nosnippet
        >admin_panel_settings</mat-icon
      >
      Admin
    </a>
    } @if ((userservice.user_info|async).isMember ) {
    <a
      mat-list-item
      routerLinkActive="active-link"
      class="list-item"
      [routerLink]="['', 'tickets']"
      (click)="toggle()"
      ><mat-icon
        style="color: var(--phonix-blue-white)"
        class="mobileIcons"
        data-nosnippet
        >bug_report</mat-icon
      >
      Tickets
    </a>
    }
    <a
      routerLinkActive="active-link"
      class="list-item"
      mat-list-item
      [routerLink]="['', 'kids']"
      style="border-top: 2px solid black"
      ><mat-icon
        style="color: var(--phonix-blue-white)"
        class="mobileIcons"
        data-nosnippet
        >groups</mat-icon
      >
      {{ "app.navbar.buttons.kids" | translate }}
    </a>
    <a
      style="border-top: 2px solid black"
      mat-list-item
      routerLinkActive="active-link"
      class="list-item"
      [routerLink]="['', 'account']"
      (click)="toggle()"
      ><mat-icon class="mobileIcons" data-nosnippet>account_circle</mat-icon>
      {{ "app.navbar.buttons.account" | translate }}
    </a>
    <a
      (click)="toggle()"
      routerLinkActive="active-link"
      class="list-item"
      mat-list-item
      [routerLink]="['', 'forums']"
    >
      <mat-icon class="mobileIcons" data-nosnippet>forum</mat-icon>
      Forums</a
    >
    <a
      (click)="toggle()"
      routerLinkActive="active-link"
      class="list-item"
      mat-list-item
      [routerLink]="['', 'contact']"
    >
      <mat-icon class="mobileIcons" data-nosnippet>contact_support</mat-icon>
      {{ "app.navbar.buttons.contact" | translate }}
    </a>
    <a mat-list-item routerLinkActive="active-link" class="list-item">
      <mat-icon class="mobileIcons kid">person_add</mat-icon>
      {{ "app.dashboard.buttons.add" | translate }}
    </a>
    } @if (!(userservice.isLogged|async)) {
    <a
      (click)="toggle()"
      routerLinkActive="active-link"
      class="list-item"
      mat-list-item
      [routerLink]="['', 'login']"
    >
      <div style="display: flex; gap: 5px">
        <mat-icon>login</mat-icon>
        {{ "app.navbar.buttons.login" | translate }}
      </div></a
    >
    <a
      (click)="openContactModal()"
      href="#"
      routerLinkActive="active-link"
      class="list-item"
      mat-list-item
    >
      <div style="display: flex; gap: 5px">
        <mat-icon>contact_support</mat-icon>

        {{ "app.navbar.buttons.contact" | translate }}
      </div>
    </a>
    <!-- <a target="_blank" mat-list-item (click)="openContact()">
      @if (!landingA) {
      <mat-icon style="color: var(--phonix-orange)" class="mobileIcons"
        >groups</mat-icon
      >
      {{ "app.navbar.buttons.research" | translate }}
      } @if (landingA) {
      <mat-icon data-nosnippet>phone_callback</mat-icon>
      {{ "app.landing.A.bookCallButton" | translate }}
      }</a
    > -->
    } @if ((userservice.isLogged|async)) {
    <a
      style="border-top: 2px solid black"
      mat-list-item
      (click)="logout()"
      [routerLink]="['']"
      routerLinkActive="router-link-active"
      ><mat-icon
        style="color: var(--phonix-red)"
        class="mobileIcons"
        data-nosnippet
        >logout</mat-icon
      >
      {{ "app.navbar.buttons.logout" | translate }}
    </a>
    }

    <a (click)="changeLang()" mat-list-item href="#">
      <div style="display: flex; gap: 5px">
        {{ "app.langObject.lang" | translate }} :
        <app-flags-icon [lang]="lang"></app-flags-icon></div
    ></a>
  </mat-nav-list>
</aside>
