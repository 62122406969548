import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UtilitiesService } from '../services/utilities/utilities.service';
import { FlagsIconComponent } from '../flags-icon/flags-icon.component';

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.css'],
  standalone: true,
  imports: [TranslateModule, FlagsIconComponent],
})
export class LanguageButtonComponent implements OnInit {
  constructor(
    private utils: UtilitiesService,
    private translater: TranslateService
  ) {
    translater.onLangChange.subscribe((res) => {
      this.lang = res.lang;
    });
  }
  lang = this.translater.currentLang;

  changeLang() {
    console.log(this.lang);
    const chosenLang = this.lang === 'fr' ? 'en' : 'fr';
    this.lang = chosenLang;

    this.utils.setLang(chosenLang);
    this.translater.use(chosenLang);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  ngOnInit(): void {
    // Initialize the language
  }
}
