@if (!(userservice.isLogged|async) && !(utils.appViewActive|async)) {
<div class="footer">
  <div class="subfooter">
    <div
      style="
        display: flex;
        flex-direction: column;
        margin-top: 1%;
        margin-bottom: 1%;
      "
    >
      <h3>{{ "app.footer.ressources" | translate }}</h3>
      <div class="buttonDiv footerButtons" style="background-color: white">
        <a
          [routerLink]="['', 'articles']"
          (click)="marketingService.sendPixel('footer', 'articles')"
          class="emailButton"
          mat-button
        >
          {{ "app.footer.news" | translate }}
        </a>
      </div>
      <div class="buttonDiv footerButtons" style="background-color: white">
        <a
          [routerLink]="['', 'nous']"
          (click)="marketingService.sendPixel('footer', 'équipe')"
          class="emailButton"
          mat-button
        >
          {{ "app.navbar.buttons.us" | translate }}
        </a>
      </div>
      <div class="buttonDiv footerButtons" style="background-color: white">
        <a
          [routerLink]="['', 'nous']"
          (click)="marketingService.sendPixel('footer', 'équipe')"
          class="emailButton"
          mat-button
        >
          <app-language-button></app-language-button>
        </a>
      </div>
    </div>
    <div style="margin-top: 1%">
      <h3>{{ "app.footer.socialmedia" | translate }}</h3>
      <div>
        <div class="footerIconsDiv">
          <a
            href="https://www.instagram.com/phonix_health"
            class="footerIcons"
            target="_blank"
          >
            <img
              alt="instagram icon"
              (click)="marketingService.sendPixel('footer', 'instagram')"
              src="https://cdn.jsdelivr.net/gh/benayed0/phonix-assets/instagram-footer.webp"
            />
          </a>
          <a
            href="https://x.com/phonix_health?s=20"
            target="_blank"
            class="footerIcons"
          >
            <img
              alt="twitter icon"
              (click)="marketingService.sendPixel('footer', 'twitter')"
              src="https://cdn.jsdelivr.net/gh/benayed0/phonix-assets/twitter-footer.webp"
          /></a>
          <a
            href="https://fr.linkedin.com/company/phonix-health"
            class="footerIcons"
            target="_blank"
          >
            <img
              alt="podcast icon"
              (click)="marketingService.sendPixel('footer', 'podcast')"
              src="../../assets/linkedin-footer.webp"
          /></a>
        </div>
        <div
          class="footerIconsDiv"
          style="margin-top: 5%; justify-content: space-around"
        >
          <a
            href="https://www.facebook.com/PhonixHealth/"
            target="_blank"
            class="footerIcons"
          >
            <img
              alt="facebook icon"
              (click)="marketingService.sendPixel('footer', 'facebook')"
              src="https://cdn.jsdelivr.net/gh/benayed0/phonix-assets/facebook-footer.webp"
          /></a>
          <!-- <a
            class="footerIcons"
            href="https://fr.linkedin.com/company/phonix-health"
            target="_blank"
          >
            <img
              alt="spotify icon"
              (click)="marketingService.sendPixel('footer', 'spotify')"
              src="https://cdn.jsdelivr.net/gh/benayed0/phonix-assets/spotify-footer.webp"
          /></a> -->
          <a
            href="https://www.youtube.com/@phonixhealth"
            class="footerIcons"
            target="_blank"
          >
            <img
              alt="youtube icon"
              (click)="marketingService.sendPixel('footer', 'youtube')"
              src="https://cdn.jsdelivr.net/gh/benayed0/phonix-assets/youtube-footer.webp"
          /></a>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        margin-top: 1%;
        margin-bottom: 1%;
      "
    >
      <h3>{{ "app.footer.reglements" | translate }}</h3>
      <div
        class="buttonDiv footerButtons"
        style="margin: 10px 0; width: auto; background-color: white"
      >
        <a
          (click)="marketingService.sendPixel('footer', 'MentionsLégales')"
          [routerLink]="['', 'legalmentions']"
          class="emailButton"
          mat-button
        >
          {{ "app.footer.legalmentions" | translate }}
        </a>
      </div>
      <!-- <div
        class="buttonDiv footerButtons"
        style="margin: 10px 0; width: auto; background-color: white"
        >
        <a
          (click)="marketingService.sendPixel('footer', 'ConditionsDeVentes')"
          [routerLink]="['', 'cgvu']"
          class="emailButton"
          mat-button
          >
          {{"app.footer.salecondition"|translate}}
        </a>
      </div> -->
      <div
        class="buttonDiv footerButtons"
        style="margin: 10px 0; width: auto; background-color: white"
      >
        <a
          (click)="
            marketingService.sendPixel('footer', 'PolitiqueDeConfidentialité')
          "
          class="emailButton"
          mat-button
          [routerLink]="['', 'politics']"
        >
          {{ "app.footer.confidentialpolitics" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
}
